import "./styles/global";
import "katex/dist/katex.css";

import { TrackJS } from "trackjs";

import { applyAuthzInterceptor, applyPreviewInterceptor } from "@pm2/api";
import { applyComponentFrameworkClassnamesMiddleware } from "@provider/component-framework";
import { userIdentityStore } from "@provider/user-identity";

import type { AppProps } from "./app";
import { authenticateAndAuthorizeUser } from "./core/authz";
import { configurePm2UserIdentityManager } from "./core/config.user-identity-manager";

if (process.env["PM2__TRACKJS__ENABLED"]) {
  TrackJS.install({
    application: process.env["PM2__TRACKJS__APP_NAME"],
    token: process.env["PM2__TRACKJS__TOKEN"] ?? "",
  });
}

const appProps: AppProps = {
  configState: window.gConfig,
  sitemapState: {
    ...window.gSitemap,
    isSitemapResolved: false,
  },
};

configurePm2UserIdentityManager(appProps.configState);
applyComponentFrameworkClassnamesMiddleware();

authenticateAndAuthorizeUser(
  appProps.configState.config,
  window.gLoginConnectorToken,
)
  .then(({ authz, user }) => {
    // apply user data to global identity store
    userIdentityStore.dispatch(userIdentityStore.actions.setUser(user ?? null));

    // apply necessary interceptors for the Axios requests to run correctly
    applyAuthzInterceptor(authz);

    if (appProps.configState.previewMode) {
      applyPreviewInterceptor();
    }
  })
  .catch((reason) => {
    console.error("unable to authenticate and authorize user:", reason);

    alert(
      "Der opstod en fejl da vi forsøgte at logge dig på. Prøv evt. at genindlæse siden.",
    );
  });

if (process.env["PM2__DISABLE_SSR"] === "true") {
  import("./bootstrap.render")
    .then(({ renderApp }) => renderApp(appProps))
    .catch((err) => {
      alert("Der opstod en fejl ved indlæsning af portalen, vi beklager.");
      console.error(err);
    });
} else {
  import("./bootstrap.hydrate")
    .then(({ hydrateApp }) => hydrateApp(appProps))
    .catch((err) => {
      alert("Der opstod en fejl ved indlæsning af portalen, vi beklager.");
      console.error(err);
    });
}

if (window.location.search.includes("ui-inspection=true")) {
  import("./dev.inspector-tool");
}
