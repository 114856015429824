import axios from "axios";

import type { PortalConfig } from "@pm2/store";

/**
 * ask the LoginConnector if the user owns a valid license for this product
 * (ie. whether or not he has been authorized to access the content).
 */
export async function authorizePortalAccess(
  loginConnectorToken: string,
  config: PortalConfig,
): Promise<boolean> {
  if (config.settings.loginFreeAccess) {
    // automatically assume that the user has been authorized to access this
    // content if it's made freely available once logged in
    return true;
  }

  const response = await axios.post<
    | undefined
    | {
        Products?: Array<{ IsAuthorized: boolean }>;
      }
  >(
    `${process.env["LOGIN_CONNECTOR_API"]}/api/ValidateSubscriptions/ValidateSubscription`,
    {
      ClientWebShopName: config.settings.loginProvider,
      ProductIds: getPortalIsbnList(config),
      SubscriptionAuthentToken: loginConnectorToken,
    },
  );

  // assume user wasn't authorized if we didn't get any useable response from
  // the LoginConnector
  if (!response.data || !response.data.Products) {
    return false;
  }

  return !!response.data.Products.find(
    (product) => product.IsAuthorized === true,
  );
}

function getPortalIsbnList(config: PortalConfig): string[] {
  // if a list of accessKeys were given, then extract valid isbns from there,
  // otherwise fall back to using the unique portal id
  const accessKeys =
    config.settings.accessKeys && config.settings.accessKeys.length > 0
      ? config.settings.accessKeys
      : [config.uniqueId];

  // now iterate over the list of accessKeys and generate a unique list of both
  // isbn10 and isbn13's
  const isbnList = new Set<string>();

  accessKeys.forEach((accessKey) => {
    const isbn10 = accessKey.substring(Math.max(0, accessKey.length - 10));
    const isbn13 = `978${isbn10}`;

    isbnList.add(isbn10);
    isbnList.add(isbn13);
  });

  return Array.from(isbnList);
}
