/**
 * translate loginProvider as given by the LoginConnector endpoint when
 * fetching user token into the AuthenticationProvider expected by PM2 SiteCore
 * backend.
 */
export function mapAuthenticationProvider(
  loginProvider: "IP" | "UNIC" | "EKEY" | string,
): "IPprovider" | "UniLogin" | "EKey" | string {
  switch (loginProvider) {
    case "IP":
      return "IPProvider";

    case "UNIC":
      return "UniLogin";

    case "EKEY":
      return "EKey";

    default:
      return loginProvider.toUpperCase();
  }
}
