import type { PortalConfig } from "@pm2/store";
import {
  getUserIdentityRequest,
  type UserIdentity,
} from "@provider/user-identity";

import { authorizePortalAccess } from "./authorizePortalAccess";
import { mapAuthenticationProvider } from "./utils";

type Authz = {
  isAuthorized: boolean;
  authenticationProvider: "IPProvider" | "UniLogin" | "EKey" | "None" | string;
  userRole: "Student" | "Teacher" | "None";
};

export async function authenticateAndAuthorizeUser(
  config: PortalConfig,
  providedLoginConnectorToken?: string,
): Promise<{
  authz: Authz;
  user?: UserIdentity;
}> {
  // assume user has been authorized without knowing anything else about him
  // if login has been disabled on the portal
  if (config.settings.loginDisabled) {
    await window.gProxy.fetchGuestToken();

    return {
      authz: {
        isAuthorized: true,
        authenticationProvider: "UniLogin",
        userRole: "Teacher",
      },
    };
  }

  // ... otherwise reach out to the LoginConnector to validate the
  const authPromise =
    providedLoginConnectorToken === undefined
      ? window.gProxy.fetchLoginConnectorToken(
          process.env["APP_ENV"] ?? "production",
          config.settings.loginProvider,
        )
      : window.gProxy.applyLoginConnectorToken(
          config.settings.loginProvider,
          providedLoginConnectorToken,
        );

  const {
    loginConnectorToken,
    userInfo: [userInfo],
  } = await authPromise;

  // if no loginConnectorToken was supplied, that means the user hasn't signed
  // properly in; provide empty authorization details
  if (!loginConnectorToken || !userInfo) {
    return {
      authz: {
        isAuthorized: false,
        authenticationProvider: "None",
        userRole: "None",
      },
    };
  }

  // once we get here, we need to determine whether user has been authorized to
  // access the content as well as the role of the user
  const authorizedPromise = authorizePortalAccess(loginConnectorToken, config);
  const user = await getUserIdentityRequest(userInfo.userId, userInfo.provider);

  return {
    authz: {
      isAuthorized: await authorizedPromise,
      authenticationProvider: mapAuthenticationProvider(userInfo.provider),
      userRole: (() => {
        switch (user.role) {
          case "student":
            return "Student";

          case "teacher":
            return "Teacher";
        }
      })(),
    },
    user: {
      ...user,
      isAuthorized: await authorizedPromise,
    },
  };
}
